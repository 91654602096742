<template>
  <div>
    <div class="container">
      <div class="form-box">
        <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
       >
       <!-- 积分分配工会 -->
        <el-form-item label="积分分配工会" prop="coding">
          <el-input v-model="form.coding" readonly size="large"  placeholder="请选择积分分配工会" @click="visible1 = true"></el-input>
        </el-form-item>
        <!-- 积分所属工会 -->
        <el-form-item label="积分所属工会" prop="identifier">
          <el-input v-model="form.identifier" readonly size="large"  placeholder="请选择积分所属工会" @click="visible = true"></el-input>
        </el-form-item>
        <!-- 分配积分值 -->
        <el-form-item label="积分值" prop="allocate">
           <el-input v-model="form.allocate"  onkeyup="value=value.replace(/[^\d]/g,'')" type="number" size="large"  placeholder="请填写分配积分值"></el-input>
        </el-form-item>
        <!-- 备注 -->
        <el-form-item label="备注" prop="remark">
           <el-input v-model="form.remark" size="large" type="textarea"  placeholder="请填写备注"></el-input>
        </el-form-item>
        <!-- 提示 -->
        <div class="hint">
           <div class="remind">提示：</div>
          <div>
            <div class="become"> 1.“积分分配工会”指的是积分由哪个工会来代理派发给会员，积分就分配给哪个工会账户。</div>
            <div class="become"> 2.“积分所属工会”指的是积分由哪个工会来实际消费。</div>
          </div>
        </div>
        
        <!-- 提交按钮 -->
        <div class="submit">
          <el-form-item>
            <el-button type="primary" :loading="loa" @click="onSubmit">提交</el-button>
          </el-form-item>
        </div>
      </el-form>
      </div>
    </div>
    <!-- 积分分配工会选择 -->
    <div>
      <s3-layer v-model="visible1" title="积分分配工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes1">
          <el-tree
            :data="texture1"
            :props="defaultProps1"
            accordion 
            @node-click="dendrogram1"
          />
        </s3-layer>
    </div>
    <!-- 积分所属工会选择 -->
    <div>
      <s3-layer v-model="visible" title="积分所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
          <el-tree
            :data="texture2"
            :props="defaultProps"
            accordion
            @node-click="dendrogram"
          />
        </s3-layer>
    </div>
  </div>
</template>

<script>
import { ref,reactive,onMounted } from "vue";
// import { Zzlist,Addlist} from '../../utils/api'
import {Zzlist,Zzzlist,ZNentry} from '../../../utils/api'
import { ElMessage } from "element-plus";
import qs from 'qs'
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
export default {
  name:"FPintegral",
  setup() {
    const route = useRoute();
    const options = ref('')
    const identifierS = ref('')
    // 积分分配工会弹窗
    let dendrogram1 = (node)=>{

      console.log(node,"---node");
      options.value=node
      form.coding=options.value.unionName
    }
    const defaultProps1 = {
        children: 'unionDtoList',
        label: 'unionName',
     }
    //  积分分配工会
    let texture1 =ref([])
    // 积分所属工会
    let texture2=ref([])
    const yes1 = () => {
      form.coding=options.value.unionName
      visible1.value = false; 
    };

    

    const visible1 = ref(false);
    // 积分所属工会弹窗
    let dendrogram = (node)=>{
      // console.log(node)
      identifierS.value=node
      form.identifier=identifierS.value.unionName
    }
    const defaultProps = {
        children: 'unionDtoList',
        label: 'unionName',
     }
    let treeData =ref([])
    const visible = ref(false);
    // 
    const yes = () => {
      // form.identifier=identifier.value.unitName
      visible.value = false; 
    };



    onMounted(()=>{
      messge()
      messges()
    })
    // 获取组织列表信息
    const messge=()=>{
      Zzlist().then((res)=>{
        console.log(res,"444444")
        texture1.value=res.data.data.unionList
      })
    }

    const messges=()=>{
      
      Zzlist().then((res)=>{
        console.log(res,"444444")
        texture2.value=res.data.data.unionList
      })
      
    }


    // 获取工会code
    let gaincode=ref('')
    // 表单内容
    const ruleFormRef = ref(null)
    const form = reactive({
      coding:"",
      identifier:"",
      allocate:"",
      remark:""
    })
    // 表单提交
    const router = useRouter();
    const loa =ref(false)
     // 关闭当前页
    const store = useStore();
    const expression = ref([])
    const current=ref('')
    const Indexs=ref(null)



    const onSubmit = ()=>{

  
      loa.value=true
      // 表单校验
      ruleFormRef.value.validate((valid)=>{
        
        if(valid==true){
          let data = {
            allocationUnionAbbreviation:options.value.unionAbbreviation,
            allocationUnionName:options.value.unionName,
            allocationUnionCode:options.value.unionCode,
            belongUnionAbbreviation:identifierS.value.unionAbbreviation,
            belongUnionName:identifierS.value.unionName,
            belongUnionCode:identifierS.value.unionCode,
            integralValue:form.allocate,
            reason:form.remark
          }
          // console.log(data)
        
        
          ZNentry(qs.parse(data)).then((res)=>{
            if(res.data.code==0){
              ElMessage({
                message: "提交成功",
                type: "success",
                center: true,
              });
              setTimeout(()=>{
				   loa.value=false
				   current.value=route.path
				   expression.value=store.state.tagsList
				   
				   expression.value.forEach((item,index)=>{
				     if(current.value==item.path){
				       Indexs.value=index
				     }
				   })
				   store.state.tagsList.splice(Indexs.value,1)
                // 关闭当前页
               router.push({
                	name: 'allocating',
                	params: {
                		userId: 1
                	}
                });
				loa.value = false
              },500)
            }else if(res.data.code!==0){
              ElMessage({
                message: res.data.msg,
                type: "error",
                center: true,
              });
              loa.value=false
            } 
          })
        }else if(valid==false){
          loa.value=false
        }
      })
    }
    // 验证表单提示
    const rules ={
      coding:[
        { required: true, message: "请选择积分分配工会", trigger: "change" },
      ],
      identifier:[
        { required: true, message: "请选择积分所属工会", trigger: "change" },
      ],
      allocate:[
        { required: true, message: "请填写分配积分值", trigger: "change" },
      ],
      remark:[
        { required: true, message: "请填写备注", trigger: "change" },
      ]
    }
    return {
      texture2,
      // 积分分配工会
      dendrogram1,
      defaultProps1,
      texture1,
      visible1,
      yes1,
      // 选择积分所属工会
      dendrogram,
      defaultProps,
      treeData,
      visible,
      yes,
      // 提交按钮
      loa,
      // 表单内容
      ruleFormRef,
      form,
      // 表单提交按钮
      onSubmit,
      rules
      
    }
  },
}
</script>

<style lang='scss' scoped>
.become{
  color: #909399;
}
.remind{
  width: 65px;
  color: #909399;
  font-size: var(--el-form-label-font-size);
  font-weight: bold;
}
.hint{
  text-align: justify;
  display: flex;
  font-size: 14px;
  margin-left: 75px;
  margin-top: -10px;
  margin-bottom: 20px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
}
::v-deep .el-form-item__label{
  font-weight: bold;
  width: 115px;
}
::v-deep .el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
::v-deep .submit{
  margin-left: 110px;
}
::v-deep .el-input{
  height: 44px;
}
::v-deep .el-form-item{
  margin-bottom: 30px;
  align-items: center;
}
::v-deep .el-tree-node__content{
  font-weight: 500;
}
::v-deep .el-select-dropdown__item.hover{
  background-color: #ffffff;
}
::v-deep .el-select-dropdown__wrap{
  background-color: #ffffff;
}
::v-deep .form-box{
  width: 500px;
}
::v-deep .el-form-item__content{
  margin-left: 0 !important;
}
::v-deep .container{
  width: 95%;
  margin: auto;
  margin-top: 10px;
  padding: 30px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>